<template>
  <div class="notifications-list">
    <NotificationsListCard v-for="notify in notifications" :key="notify._id" :notification="notify" />
    <CardMessage v-if="!$loading && !totalNotifications" :message="$locale['no_has_notifications']" />
  </div>
</template>

<script>
export default {
  components: {
    NotificationsListCard: () => import("../notifications/NotificationsListCard.vue"),
  },
  methods: {
    getNotifications: async function() {
      this.isLoading(true);
      await this.$store.dispatch("getNotifications", {});
      this.isLoading(false);
    },
  },
  mounted: function() {
    this.getNotifications();
  },
  computed: {
    notifications: function() {
      return this.$store.state.notifications.notifications;
    },
    totalNotifications: function() {
      return this.$store.state.notifications.statistics.total;
    },
  },
};
</script>

<style lang="scss">
.notifications-list {
  position: relative;
  padding: $mpadding/2 0;
}
</style>
